<template>
    <div>
        <div class="font"><span>计算结果：</span> </div>
        <div class="result-main" v-for="(item, index) in result" :key="index">
            <div class="font font-result">
                <span>{{ item.name }}</span>
                <b>{{ item.money }}</b>
                <strong>元</strong>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'calculator-result-classification',
        props: ['result'],
    }
</script>

<style scoped lang="less">
    .font {
        font-size: 20px;
        margin-top: 0.3rem;
        color: #333;

        span {
            display: inline-block;
            width: 3rem;
        }
        b,
        strong {
            color: #333;
            font-weight: normal;
        }
    }

    .result-main:last-child {
        .font-result {
            font-weight: 900;
            font-size: 24px;
            color: #333;

            b,
            strong {
                color: #333;
                font-weight: 600;
            }
        }
    }

    b {
        margin: 0 0.05rem;
    }
    strong {
        font-size: 16px;
    }
</style>
